import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { Announcement, AnnouncementSearch, UserAnnouncementSearch } from '../model/announcement.model';
import { logRequest } from '../util/request-util';
import { FileUploadService } from './file-upload.service';

@Injectable({
    providedIn: 'root',
})
export class AnnouncementService implements FileUploadService {
    private http = inject(HttpClient);

    constructor() {
        console.log('Announcement service created');
    }

    get baseUrl() {
        return `${environment.apiUrl}/school-service/api/announcements`;
    }

    getById(id: string): Observable<Announcement> {
        return this.http.get<Announcement>(this.baseUrl + '/' + id);
    }

    search(search: AnnouncementSearch): Observable<HttpResponse<Announcement[]>> {
        //search.schoolId = this.schoolService.getActiveSchool().id;
        return this.http.post<Announcement[]>(`${this.baseUrl}/search`, search, {
            observe: 'response',
        });
    }

    create(announcement: Announcement, formData: FormData) {
        if (!formData) {
            formData = new FormData();
        }
        if (announcement) {
            //announcement.schoolId = this.schoolService.getActiveSchool().id;
            formData.set(
                'dto',
                new Blob([JSON.stringify(announcement)], {
                    type: 'application/json',
                })
            );
        }
        return this.http.post<Announcement>(this.baseUrl, formData, {
            reportProgress: true,
            headers: new HttpHeaders({
                'Content-Type': 'multipart/form-data',
                'X-Log-Request': 'true',
            }),
        });
    }

    update(announcement: Announcement, formData: FormData) {
        if (!formData) {
            formData = new FormData();
        }
        if (announcement) {
            //announcement.schoolId = this.schoolService.getActiveSchool().id;
            formData.set(
                'dto',
                new Blob([JSON.stringify(announcement)], {
                    type: 'application/json',
                })
            );
        }
        return this.http.put<Announcement>(this.baseUrl, formData, {
            reportProgress: true,
            headers: new HttpHeaders({
                'Content-Type': 'multipart/form-data',
                'X-Log-Request': 'true',
            }),
        });

        // return this.http.put<Announcement>(this.baseUrl, announcement, { observe: 'response' });
    }

    delete(id: string): Observable<HttpResponse<any>> {
        return this.http.delete(`${this.baseUrl}/${id}`, {
            observe: 'response',
            headers: logRequest(),
        });
    }

    publish(id: string): Observable<HttpResponse<Announcement>> {
        return this.http.patch<Announcement>(`${this.baseUrl}/publish/${id}`, null, {
            observe: 'response',
            headers: logRequest(),
        });
    }

    unpublish(id: string): Observable<HttpResponse<Announcement>> {
        return this.http.patch<Announcement>(`${this.baseUrl}/unpublish/${id}`, null, {
            observe: 'response',
            headers: logRequest(),
        });
    }

    getUserAnnouncements(searchDTO: UserAnnouncementSearch): Observable<HttpResponse<Announcement[]>> {
        return this.http.post<Announcement[]>(`${this.baseUrl}/user-announcements`, searchDTO, {
            observe: 'response',
        });
    }

    getUserAnnouncementsCount(searchDTO: UserAnnouncementSearch): Observable<HttpResponse<any>> {
        return this.http.post<any>(this.baseUrl + '/user-announcements-count', searchDTO, {
            observe: 'response',
        });
    }

    hideAnnouncement(id: string): Observable<HttpResponse<any>> {
        return this.http.post<Announcement>(this.baseUrl + '/' + id + '/hide', null, {
            observe: 'response',
            headers: logRequest(),
        });
    }

    downloadFile(announcementId: string, attachmentUrl: string) {
        return this.http.get(this.baseUrl + '/' + announcementId + '/attachment/' + attachmentUrl + '/download', {
            responseType: 'blob',
            headers: logRequest(),
        });
    }

    deleteFile(announcementId: string, attachmentUrl: string) {
        return this.http.delete(this.baseUrl + '/' + announcementId + '/attachment/' + attachmentUrl, {
            headers: logRequest(),
        });
    }

    downloadByAttachmentURL(attachmentUrl: string) {}
}
